<template>
  <div id="app">
    <Navbar></Navbar>
    <div v-if="authState !== 'signedin'">You are signed out.</div>
    <amplify-authenticator>
      <amplify-sign-in slot="sign-in" username-alias="email" hideSignUp>
        <!-- removes AWS login button -->
        <div slot="federated-buttons"></div> 
      </amplify-sign-in>
      <div v-if="authState === 'signedin' && user && show_logged_in_as">
        <div style="padding-top: 0.5em">Logged in as: {{user.attributes.email}}</div>
      </div>
      <div class="contents">
        <br>
          <router-view />
      </div>
      <amplify-sign-out id="sign-out" v-if="show_signout"></amplify-sign-out>
    </amplify-authenticator>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";
import { onAuthUIStateChange } from '@aws-amplify/ui-components';

export default {
    name: "app",
    components: {
        Navbar,
    },
    data: () => {
        return {
            user: undefined,
            authState: undefined,
            unsubscribeAuth: undefined,
            formFields: [
              { type: 'password' },
              { type: 'email' }
            ],
        };
    },

    created() {
      this.unsubscribeAuth = onAuthUIStateChange((authState, authData) => {
        this.authState = authState;
        this.user = authData;
        if (authState === 'signedin') {
          localStorage.setItem('token', authData.signInUserSession.accessToken.jwtToken);
        } else {
          localStorage.removeItem('token');
        }
      });
    },
    
    beforeDestroy() {
      this.unsubscribeAuth();
    },

    computed: {
      show_logged_in_as() {
        return (this.$route.name == "Home");
      },

      show_signout() {
        return (this.$route.name == "Home");
      }
    },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /* margin-top: 60px; */
}

/* Handle reseting style conflicts with ULabel */
#container {
  line-height: initial;
}

#container p {
  margin-block: initial;
}

#container label {
  margin: initial;
}

#container input[type=checkbox] {
  transform: initial;
}

#container div.recolor-active input[type=checkbox] {
  margin-left: 0.25rem;
}

#container div.recolor-active div.gradient-toggle-container {
  margin-bottom: 0.25rem;
}

#container fieldset {
  margin: initial;
  padding: 4px;
  padding-top: 0;
  border: 1px solid black;
  border-radius: 2px;
}

#container legend {
  font-size: initial;
  width: initial;
  max-width: initial
}

/* Directly reset normally inherited property */
#container fieldset.filter-row-distance-options > legend {
  font-size: 100%;
}

#container div.toolbox-class-counter > p:last-child {
  line-height: 1.5rem;
  margin-bottom: 0.5rem;
}

amplify-sign-out {
  width: 100vw;
  position: fixed;
  bottom: 0;
  left: 0;
}
</style>
